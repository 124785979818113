export const totalAssets = (contracts, customers) => {
  const today = new Date();
  if (contracts && customers) {
    const updatedData = contracts.map(contract => {
      const matchingCustomers = customers.filter(customer => customer.contract === contract.id);
      const total_asset = matchingCustomers.length;

      return { ...contract, total_asset };
    });
    const updates = updatedData.map(item => {
      const coverageStart = new Date(item.coverage_start);
      const coverageEnd = new Date(item.coverage_end);
      if (today >= coverageStart && today <= coverageEnd) {
        item.status = 'Active';
      } else {
        item.status = 'Expired';
      }
      return item;
    });
    return updates;
  }
};

export const insertStatus = initialContractData => {
  if (initialContractData) {
    const today = new Date();

    // @ts-ignore
    function getStatus(startDate, endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      if (today < startDateObj) {
        return 'Awaiting Start';
      } else if (startDateObj <= today && (!endDate || today <= endDateObj)) {
        return 'Open';
      } else {
        return 'Completed';
      }
    }

    const updatedContractData = initialContractData.map(contract => ({
      ...contract,
      status: getStatus(contract.start_date, contract.expiry_date),
    }));

    return updatedContractData;
  }
};

// function to sort data for donut chart and count products
export const contractProducts = data => {
  if (!data) {
    return [];
  }

  const product = data?.reduce((productCount: any, contract: any) => {
    productCount[contract?.support_product] = (productCount[contract?.support_product] || 0) + 1;
    return productCount;
  }, {});

  const chartData = Object.keys(product).map(key => ({
    name: key,
    value: product[key],
  }));

  return chartData;
};
