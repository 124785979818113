import { Badge, Tooltip, Text } from '@mantine/core';
import dayjs from 'dayjs';

export const TICKET_COLUMNS = [
  {
    Header: 'Ticket Name',
    accessor: 'subject',
    Cell: ({ value }) => (
      <Tooltip
        label={value || 'Not found'}
        color="gray.9"
        c="gray.0"
        fw={500}
        p="sm"
        multiline
        w={200}
        styles={{
          tooltip: {
            fontSize: 14,
          },
        }}
      >
        <Text size="sm" truncate>
          {value || 'Not found'}
        </Text>
      </Tooltip>
    ),
  },
  {
    Header: 'Ticket #',
    accessor: 'hs_object_id',
    Cell: ({ value }) => (
      <Tooltip
        label={value || 'Not found'}
        color="gray.9"
        c="gray.0"
        fw={500}
        p="sm"
        multiline
        styles={{
          tooltip: {
            fontSize: 14,
          },
        }}
      >
        <Text size="sm" truncate>
          {value || 'Not found'}
        </Text>
      </Tooltip>
    ),
  },
  {
    Header: 'Impact to Network',
    accessor: 'impact_to_network',
    Cell: ({ value }) => {
      const formater = value?.split('-');
      const splited = value && formater[0].trim();
      const tooltip = formater ? formater[1] : '';
      let badgeType = { background: '#E4EEFD', color: '#1F429F', padding: 5, paddingInline: 40 };

      return (
        <Tooltip
          label={`${tooltip ? tooltip : 'Not found'}`}
          color="gray.9"
          c="gray.0"
          fw={500}
          p="sm"
          styles={{
            tooltip: {
              fontSize: 14,
            },
          }}
        >
          <Badge bg={badgeType?.background} c={badgeType?.color}>
            {splited ?? 'Not found'}
          </Badge>
        </Tooltip>
      );
    },
  },
  {
    Header: 'Category',
    accessor: 'hs_ticket_category',
    Cell: ({ value }) => (
      <Tooltip
        label={value || 'Not found'}
        color="gray.9"
        c="gray.0"
        fw={500}
        p="sm"
        multiline
        styles={{
          tooltip: {
            fontSize: 14,
          },
        }}
      >
        <Text size="sm" truncate>
          {value || 'Not found'}
        </Text>
      </Tooltip>
    ),
  },
  {
    Header: 'Serial Number',
    accessor: 'item_serial_number',
    Cell: ({ value }) => (
      <Tooltip
        label={value || 'Not found'}
        color="gray.9"
        c="gray.0"
        fw={500}
        p="sm"
        multiline
        styles={{
          tooltip: {
            fontSize: 14,
          },
        }}
      >
        <Text size="sm" truncate>
          {value || 'Not found'}
        </Text>
      </Tooltip>
    ),
  },
  {
    Header: 'Last Activity Date',
    accessor: 'hs_lastactivitydate',
    Cell: ({ value }) => <span>{value ? dayjs(new Date(value)).format('MMM DD, YYYY') : 'Not found'}</span>,
  },
  {
    Header: 'Create Date',
    accessor: 'createdate',
    Cell: ({ value }) => <span>{value ? dayjs(new Date(value)).format('MMM DD, YYYY') : 'Not found'}</span>,
  },
  {
    Header: 'Status',
    filter: 'equals',
    accessor: 'hs_pipeline_stage',
    Cell: ({ value }) => {
      const statusMap = {
        1040305: 'Triage',
        1040814: 'Tech Analysis',
        1040307: 'Tech Analysis',
        1040306: 'Awaiting Reply',
        92389298: 'SLA Complete',
        1040308: 'Ticket Completed',
      };

      const status = statusMap[value];

      let badgeType: any = {};

      if (status === 'Ticket Completed') {
        badgeType = { background: '#E2F7EC', color: '#02543F' };
      } else if (status === 'Tech Analysis') {
        badgeType = { background: '#FFEAB6', color: '#000000' };
      } else if (status === 'Awaiting Reply') {
        badgeType = { background: '#E4EEFD', color: '#1F429F' };
      } else if (status === 'Triage') {
        badgeType = { background: '#F9DCD0', color: '#F05821' };
      } else {
        badgeType = { background: '#F9DCD0', color: '#000000' };
      }

      return (
        <Badge bg={badgeType?.background} c={badgeType?.color}>
          {status || 'No Status'}
        </Badge>
      );
    },
  },
];
