import { SUPABASE_KEYS } from '@/config/supabase-keys';
import { EquipmentType } from '@/types';
import { supabase } from '@/utils/supabase/client';
import dayjs from 'dayjs';

const PAGE_SIZE = 10;

const getCustomerAssets = async (pageIndex: number, id: string) => {
  const range = pageIndex ? pageIndex - 1 : 0;
  const offset = range * PAGE_SIZE;

  const { data, count } = await supabase
    .from(SUPABASE_KEYS.CUSTOMER_ASSETS)
    .select('*', { count: 'exact' })
    .contains('customer_contract', [id])
    .range(offset, offset + PAGE_SIZE - 1);

  // set total number of pages
  const PAGE_COUNT = Math.ceil(count / PAGE_SIZE);

  return {
    data: data ?? [],
    pageCount: PAGE_COUNT,
  };
};

const getAssetById = async (id: string): Promise<any> => {
  const { data } = await supabase.from(SUPABASE_KEYS.CUSTOMER_ASSETS).select().eq('id', id).limit(10);

  return data;
};

const getAssetSerialId = async (id: any): Promise<any> => {
  const { data, error } = await supabase.from(SUPABASE_KEYS.CUSTOMER_ASSETS).select('*').eq('serial_number', id);

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const getAssetSerialNumber = async (item: string): Promise<EquipmentType[]> => {
  const { data, error } = await supabase
    .from(SUPABASE_KEYS.CUSTOMER_ASSETS)
    .select('*')
    .ilike('serial_number', `%${item}%`)
    .lte('asset_coverage_start', dayjs().format('YYYY-MM-DD'))
    .gte('asset_coverage_end', dayjs().format('YYYY-MM-DD'))
    .limit(1);

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const getChartSummary = async (): Promise<any> => {
  const { data, error } = await supabase.rpc('get_assets_summary');

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

const getGlobalSearch = async (searchValue: string): Promise<any> => {
  const { data, error } = await supabase.rpc('get_global_search', {
    search_param: searchValue,
  });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export { getCustomerAssets, getAssetById, getAssetSerialId, getAssetSerialNumber, getChartSummary, getGlobalSearch };
