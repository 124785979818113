import { monthFormatter } from '@/components/helper/format-date';
import dayjs from 'dayjs';
import { ResponsiveContainer, Tooltip, LineChart, CartesianGrid, Line, XAxis, YAxis, Dot } from 'recharts';

export const CustomLineChart = ({ data, xAxisKey }) => {
  const CustomizedDot = (props: any) => {
    const { cx, cy, stroke, fill } = props;
    return <Dot cx={cx} cy={cy} r={3} stroke={`${stroke}`} strokeWidth={1} fill={`${fill}`} />;
  };

  const formatNumber = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const formatDate = value => {
    return dayjs(value).format('MMM-DD-YYYY');
  };

  return (
    <ResponsiveContainer width="100%" height={400} minWidth={300}>
      <LineChart width={700} height={200} data={data} margin={{ top: 5, right: 25, left: -20, bottom: 25 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey={`${xAxisKey}`}
          axisLine={false}
          tickLine={false}
          interval={0}
          tickFormatter={date => {
            return monthFormatter(date);
          }}
          tickMargin={5}
          tick={{ fontSize: 12 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={5}
          allowDecimals={false}
          tick={{
            fontSize: 12,
          }}
          tickFormatter={value => formatNumber(value)}
        />

        <Tooltip cursor={{ fill: 'transparent' }} labelFormatter={value => formatDate(value)} />

        <Line type="monotone" dataKey="count" stroke="#0088FE" strokeWidth={3} dot={<CustomizedDot />} />
      </LineChart>
    </ResponsiveContainer>
  );
};
