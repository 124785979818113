import { ResponsiveContainer, Tooltip, BarChart, CartesianGrid, Bar, XAxis, YAxis, Cell, LabelList } from 'recharts';

const COLORS = {
  Open: {
    background: '#e2f7ec',
    border: '#02543f',
  },
  Completed: {
    background: '#FFEAB6',
    border: 'black',
  },
  'Awaiting start': {
    background: '#e4eefd',
    border: '#1f429f',
  },
};

export const CustomBarChart = ({ data, xAxisKey }) => {
  const formatNumber = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <ResponsiveContainer width="100%" minHeight={400} minWidth={300}>
      <BarChart width={700} height={200} data={data} margin={{ top: 15, right: 30, left: 0, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey={`${xAxisKey}`}
          tickMargin={5}
          tick={{ fontSize: 12 }}
          tickFormatter={name => (name.length > 15 ? name.substring(0, 3) + '...' : name)}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={5}
          tick={{
            fontSize: 12,
          }}
          tickFormatter={value => formatNumber(value)}
        />

        <Tooltip cursor={{ fill: 'transparent' }} />
        <Bar dataKey="value">
          {data?.map((entry, index) => {
            const color = COLORS[entry.name];

            return <Cell key={index} fill={color?.background || '#F05822'} />;
          })}
          <LabelList dataKey="value" position="top" fill="black" fontSize={10} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
