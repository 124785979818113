import { Badge, Text, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)


export const CONTRACT_COLUMNS = [
  {
    Header: 'Contract #',
    accessor: 'internal_contract_id',

    Cell: ({ value }) => (
      <Tooltip
        label={value || 'Data not found'}
        color="gray.9"
        c="gray.0"
        fw={500}
        p="sm"
        multiline
        styles={{
          tooltip: {
            fontSize: 14,
          },
        }}
      >
        <Text size="sm" truncate>
          {value || 'Not found'}
        </Text>
      </Tooltip>
    ),
  },
  {
    Header: 'Coverage Start',
    accessor: 'start_date',
    Cell: ({ value }) => <span>{value ? dayjs(new Date(value)).utc().format('MMM DD, YYYY ') : 'Not found'}</span>,
  },
  {
    Header: 'Coverage End',
    accessor: 'expiry_date',
    Cell: ({ value }) => <span>{value ? dayjs(new Date(value)).utc().format('MMM DD, YYYY ') : 'Not found'}</span>,
  },
  {
    Header: 'Covered Assets',
    accessor: 'asset_count',
    Cell: ({ value }) => <span>{Number(value) || 0}</span>, // Convert value to a number using Number()
  },

  {
    Header: 'Status',
    accessor: 'status',
    filter: 'equals',
    disableSortBy: true,
    Cell: ({ value }) => {
      let badgeType: any = {};

      switch (
        value // Use optional chaining to handle if "sla" property is not present
      ) {
        case 'Open':
          badgeType = { background: '#E2F7EC', color: '#02543F', padding: 5, paddingInline: 10 };
          break;
        case 'Awaiting Start':
          badgeType = { background: '#E4EEFD', color: '#1F429F', padding: 5, paddingInline: 10 };
          break;
        default:
          badgeType = { background: '#FFEAB6', color: '#000000', padding: 5, paddingInline: 10 };
      }

      return (
        <Badge bg={badgeType?.background} c={badgeType?.color} size="lg">
          {value ? value : 'No Status'} {/* Use optional chaining */}
        </Badge>
      );
    },
  },
];
