import { useMantineTheme } from '@mantine/core';
import { ResponsiveContainer, PieChart, Pie, Tooltip } from 'recharts';

export const DonutChart = ({ data }) => {
  const theme = useMantineTheme();

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          cx="50%"
          cy="50%"
          innerRadius="60%"
          outerRadius="80%"
          fill={theme.colors.pivit[6]}
          paddingAngle={2}
          dataKey="value"
          data={data}
          label
        />

        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
