import { getContractById, getContracts, getContractAssetsId, getActiveContracts } from '@/api/contract';
import { ContractResponse } from '@/api/contract';
import { QUERIES } from '@/config/query-keys';
import { ContractsType } from '@/types';
import { useQuery } from '@tanstack/react-query';

export function useContracts(search: string, pageIndex: number, filterVariable: string, sortBy: any[]) {
  return useQuery<ContractResponse>({
    queryKey: QUERIES.CONTRACTS(search, pageIndex, filterVariable, sortBy),
    queryFn: () => getContracts(search, pageIndex, filterVariable, sortBy),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}

export function useContractById(id: string) {
  return useQuery<ContractsType>({
    queryKey: QUERIES.CONTRACT(id),
    queryFn: () => getContractById(id),
    enabled: !!id,
  });
}

export function useContractAssetId(id: string, options?: any) {
  return useQuery<ContractsType>({
    queryKey: QUERIES.CONTRACT(id),
    queryFn: () => getContractAssetsId(id),
    ...options,
  });
}

export function useActiveContract() {
  return useQuery<ContractsType[]>({
    queryKey: QUERIES.CONTRACT_HISTORY,
    queryFn: () => getActiveContracts(),
  });
}
