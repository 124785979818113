import dayjs from 'dayjs';

export const monthFormatter = (date: string) => {
  return dayjs(date, 'DD-MM-YYYY').format('MMM-DD');
};

// function to sort dates if needed from oldest to newest
export function sortDates(a, b) {
  const dateA = dayjs(a.date);
  const dateB = dayjs(b.date);

  return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
}
