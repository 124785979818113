'use client';

import { CustomBarChart } from '@/components/charts/bar-chart';
import { DonutChart } from '@/components/charts/donut-chart';
import { CustomLineChart } from '@/components/charts/line-chart';
import { Table } from '@/components/common/table';
import { CONTRACT_COLUMNS } from '@/components/contracts/main/table-columns';
import { TICKET_COLUMNS } from '@/components/tickets/main/table-columns';
import { useChartSummary } from '@/hooks/assets';
import { useActiveContract } from '@/hooks/contracts';
import { useTicketHistory } from '@/hooks/tickets';
import useTrackingCode from '@/hooks/tracking-code';
import { useUserStore } from '@/store/store';
import { Box, Flex, Grid, LoadingOverlay, Paper, Title, useMantineTheme } from '@mantine/core';
import { useRouter } from 'next/navigation';
import React from 'react';

const Home = () => {
  const router = useRouter();
  const theme = useMantineTheme();
  const { setIdentity, setTrackPageView } = useTrackingCode();
  const user = useUserStore();

  const onTicketDetail = (row: any) => {
    router.push(`/app/tickets/${row.id}`);
  };

  const onContractDetail = (row: any) => {
    router.push(`/app/contracts/${row.id}?internal_contract_id=${row.internal_contract_id}`);
  };

  const { data: ticketHistory, isSuccess } = useTicketHistory();
  const { data: activeContract, isSuccess: isContract } = useActiveContract();
  const { data: chartSummary, isSuccess: chartSuccess, isLoading: chartLoading } = useChartSummary();
  const { assets_per_sla, assets_per_status, product_per_contract, tickets_per_week } = chartSuccess && chartSummary;

  const revertData = React.useMemo(() => {
    return tickets_per_week ? [...tickets_per_week].reverse() : [];
  }, [tickets_per_week]);

  const handleTracker = React.useCallback(() => {
    if (user) {
      setIdentity(user.email);
      setTrackPageView();
    }
  }, [user]);

  React.useEffect(() => {
    handleTracker();
  }, [handleTracker]);

  return (
    <Grid gutter="xl">
      <Grid.Col
        span={{
          sm: 12,
          lg: 6,
          xl: 8,
        }}
      >
        <Paper
          withBorder
          radius="md"
          styles={{
            root: {
              borderColor: theme.colors.gray[2],
            },
          }}
        >
          <Title order={4} c="gray.8" m="lg">
            Number of tickets (last 30 days)
          </Title>
          <Box pos="relative" mt="lg">
            <LoadingOverlay
              visible={chartLoading}
              loaderProps={{
                size: 50,
                type: 'bars',
              }}
              overlayProps={{ radius: 'sm', blur: 3 }}
            />

            <CustomLineChart data={revertData} xAxisKey="week_start_date" />
          </Box>
        </Paper>
      </Grid.Col>

      <Grid.Col
        span={{
          sm: 12,
          lg: 6,
          xl: 4,
        }}
      >
        <Paper
          withBorder
          radius="md"
          styles={{
            root: {
              borderColor: theme.colors.gray[2],
            },
          }}
        >
          <Title order={4} c="gray.8" m="lg">
            Contracts by Support Product
          </Title>
          <Flex align="center" justify="center" pos="relative">
            <LoadingOverlay
              visible={chartLoading}
              loaderProps={{
                size: 50,
                type: 'bars',
              }}
              overlayProps={{ radius: 'sm', blur: 3 }}
            />

            <DonutChart data={product_per_contract}></DonutChart>
          </Flex>
        </Paper>
      </Grid.Col>

      <Grid.Col
        span={{
          sm: 12,
          md: 6,
        }}
      >
        <Paper
          withBorder
          radius="md"
          styles={{
            root: {
              borderColor: theme.colors.gray[2],
            },
          }}
        >
          <Title order={4} c="gray.8" m="lg">
            Count of Assets by Status
          </Title>
          <Box pos="relative">
            <LoadingOverlay
              visible={chartLoading}
              loaderProps={{
                size: 50,
                type: 'bars',
              }}
              overlayProps={{ radius: 'sm', blur: 3 }}
            />
            <CustomBarChart data={assets_per_status} xAxisKey="name" />
          </Box>
        </Paper>
      </Grid.Col>

      <Grid.Col
        span={{
          sm: 12,
          md: 6,
        }}
      >
        <Paper
          withBorder
          radius="md"
          styles={{
            root: {
              borderColor: theme.colors.gray[2],
            },
          }}
        >
          <Title order={4} c="gray.8" m="lg">
            Count of Assets by SLA
          </Title>
          <Box pos="relative">
            <LoadingOverlay
              visible={chartLoading}
              loaderProps={{
                size: 50,
                type: 'bars',
              }}
              overlayProps={{ radius: 'sm', blur: 3 }}
            />
            <CustomBarChart data={assets_per_sla} xAxisKey="name" />
          </Box>
        </Paper>
      </Grid.Col>

      {isSuccess && (
        <Grid.Col span={12}>
          <Paper
            withBorder
            radius="md"
            styles={{
              root: {
                borderColor: theme.colors.gray[2],
              },
            }}
          >
            <Table
              columns={TICKET_COLUMNS}
              data={ticketHistory ?? []}
              onRowClick={onTicketDetail}
              actions={{ edit: false, delete: false }}
              isFilters={[]}
            >
              <Title order={4} mt="lg" mx="lg">
                Your Ticket History
              </Title>
            </Table>
          </Paper>
        </Grid.Col>
      )}

      {isContract && (
        <Grid.Col span={12}>
          <Paper
            withBorder
            radius="md"
            styles={{
              root: {
                borderColor: theme.colors.gray[2],
              },
            }}
          >
            <Table
              columns={CONTRACT_COLUMNS}
              data={activeContract ?? []}
              onRowClick={onContractDetail}
              actions={{ edit: false, delete: false }}
              isFilters={[]}
            >
              <Title order={4} m="lg">
                Recent Active Contracts
              </Title>
            </Table>
          </Paper>
        </Grid.Col>
      )}
    </Grid>
  );
};

Home.isProtectedRoute = true; // Mark the page as a protected route

export default Home;
