import { insertStatus } from '@/components/helper/contracts';
import { SUPABASE_KEYS } from '@/config/supabase-keys';
import { ContractsType } from '@/types';
import { supabase } from '@/utils/supabase/client';

export interface ContractResponse {
  data: ContractsType[];
  pageCount: number;
}

const PAGE_SIZE = 10;

const getContracts = async (
  searchQuery: string,
  pageIndex: number,
  filterVariable: string,
  sortBy: any[],
): Promise<ContractResponse> => {
  const range = pageIndex ? pageIndex - 1 : 0;
  const offset = range * PAGE_SIZE;
  const currentDate = new Date().toISOString();
  let query = supabase.from(SUPABASE_KEYS.CONTRACTS).select(`*`, { count: 'exact' });

  if (searchQuery) {
    query = query.ilike('internal_contract_id', `%${searchQuery}%`);
  }

  if (offset) {
    query = query.range(offset, offset + PAGE_SIZE - 1);
  }

  const filterActions = {
    Open: () => query.lte('start_date', currentDate).gte('expiry_date', currentDate),
    Completed: () => query.lt('expiry_date', currentDate),
    'Awaiting Start': () => query.gt(`start_date`, currentDate),
  };

  if (filterVariable && filterActions.hasOwnProperty(filterVariable)) {
    query = filterActions[filterVariable]();
  }

  if (sortBy) {
    if (sortBy.length > 0) {
      query = query.order(sortBy[0].id, {
        ascending: sortBy[0].desc,
      });
    } else {
      query = query.order('start_date', { ascending: false });
    }
  }

  const { data: filteredData, count } = await query.limit(10);
  const PAGE_COUNT = Math.ceil(count / PAGE_SIZE);
  const data = await insertStatus(filteredData);

  return {
    data: data ?? [],
    pageCount: PAGE_COUNT,
  };
};

const getContractById = async (id: string): Promise<ContractsType> => {
  const { data, error } = await supabase.from(SUPABASE_KEYS.CONTRACTS).select().eq('id', id).single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

const getContractAssetsId = async (id: string): Promise<ContractsType[]> => {
  const { data, error } = await supabase.from(SUPABASE_KEYS.CONTRACTS).select('*').contains('assets', [id]).single();

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const getActiveContracts = async (): Promise<ContractsType[]> => {
  const currentDate = new Date().toISOString();
  let query = supabase
    .from(SUPABASE_KEYS.CONTRACTS)
    .select('*')
    .lte('start_date', currentDate)
    .gte('expiry_date', currentDate)
    .not('start_date', 'is', null)
    .order('start_date', { ascending: false })
    .limit(3);

  const { data: contractData } = await query;

  if (contractData) {
    const data = await insertStatus(contractData);

    return data;
  }
};

export { getContracts, getContractById, getContractAssetsId, getActiveContracts };
