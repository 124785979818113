import { getAssetById, getAssetSerialNumber, getChartSummary, getCustomerAssets, getGlobalSearch } from '@/api/assets';
import { QUERIES } from '@/config/query-keys';
import { EquipmentType } from '@/types';
import { useQuery } from '@tanstack/react-query';

export function useAssets(pageIndex: number, id: string) {
  return useQuery({
    queryKey: QUERIES.ASSETS(pageIndex, id),
    queryFn: () => getCustomerAssets(pageIndex, id),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}

export function useAssetById(id: string, options?: any) {
  return useQuery({
    queryKey: QUERIES.ASSET(id),
    queryFn: () => getAssetById(id),
  });
}

export function useAssetSerialNumber(search: string, options?: any) {
  return useQuery<EquipmentType[]>({
    queryKey: QUERIES.ASSETAUTOCOMPLETE(search),
    queryFn: () => getAssetSerialNumber(search),
    ...options,
  });
}

// export function useAssetSerialId(id: string, options?: any) {
//   return useQuery({
//     queryKey: QUERIES.ASSETAUTOCOMPLETE(id),
//     queryFn: () => getAssetSerialId(id),
//   });
// }

export function useChartSummary() {
  return useQuery({
    queryKey: QUERIES.CHART_SUMMARY,
    queryFn: () => getChartSummary(),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}

export function useGlobalSearch(searchValue: string, options?: any) {
  return useQuery({
    queryKey: QUERIES.GLOBAL_SEARCH(searchValue),
    queryFn: () => getGlobalSearch(searchValue),
    ...options,
  });
}
